.about-section{

  &__images--index{

	max-width: 19.20131rem;
	width: 100%;
	margin: 0 auto;

	@include breakpoint(sm){
	  max-width: 30rem;
	}

	@include breakpoint(xl){
	  max-width: 42.65rem;
	}

	.img{
	  align-self: flex-end;
	  width: 14.48206rem;
	  height: 11.34031rem;
	  object-fit: cover;
	  object-position: center;

	  @include breakpoint(sm){
		width: 26rem;
		height: 19rem;
	  }

	  @include breakpoint(xl){
		width: 32.16756rem;
		height: 25.18906rem;
	  }
	}

	.img_before{
	  z-index: 0;
	  width: 7.75rem;
	  left: -0.5rem;
	  bottom: 4rem;
	  position: absolute;

	  @include breakpoint(xl){
		width: 16rem;
		bottom: 7rem;
	  }
	}

	.img_after{
	  position: absolute;
	  width: 11rem;
	  right: -1rem;
	  bottom: -1.5rem;
	  z-index: 3;

	  @include breakpoint(xl){
		width: 19.67363rem;
		right: -2rem;
		bottom: -3.5rem;
	  }
	}

	.about-section__images_desc{
	  width: 9.6875rem;
	  margin-top: -4rem;
	  font-size: 0.75rem;

	  @include breakpoint(xl){
		width: 19.47338rem;
		padding: 2.46rem 2.15rem;
		font-size: 1.25rem;
		margin-top: -8rem;
	  }
	}
  }
}