.slide-card{
  text-align: center;
  margin: 0 auto;

  &__mask{
	margin: 0 auto 1rem;

	@include breakpoint(lg){
	  margin: 0 auto 1.5rem;
	}
  }

  &__title{
	margin-bottom: 0.38rem;
	transition: 0.5s all ease;

	@include breakpoint(lg){
	  margin-bottom: 1.5rem;
	}
  }

  a.slide-card__title{
	&:hover{
	  color: $accent-400;
	}
  }

  &__description{
	max-width: 12.3125rem;
	margin: 0 auto;

	@include breakpoint(md){
	  max-width: 100%;
	}
  }
}