.pagination{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.82rem;

  @include breakpoint(lg){
	margin-top: 3.9rem;
  }

  li{

	a, p{
	  border-radius: 50%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  width: 2.5rem;
	  height: 2.5rem;
	  line-height: 2.5rem;
	  background-color: $white;
	  border: 2px solid transparent;
	  font-size: 1.125rem;
	}

	a:hover{
	  opacity: .8
	}

	p{
	  border-color:$accent-300;
	  color:$accent-300;
	  font-weight: bold;
	}
  }

  &__prev, &__next{
	position: relative;
	background-color: $accent-300!important;

	&:after{
	  content: '';
	  background: url("../../assets/images/fi-br-arrow-small-right-w.svg")no-repeat;
	  background-size: 100% 100%;
	  width: 1.5rem;
	  height: 1.5rem;
	}

	&:hover{
	  opacity: 0.8;
	}
  }

  &__prev{
	margin-right: 0.75rem;
	&:after{
	  transform: rotate(180deg);
	}
  }

  &__next{
	margin-left: 0.75rem;
  }
}