.blog-card{

  &__mask{
	margin:0 0 1rem;
  }

  &__caption{
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 0.25rem;

	@include breakpoint(sm){
	  text-align: left;
	  gap: 1.12rem;
	}
  }

  &__title{
	transition: 0.5s all ease;

	&:hover{
	  color: $accent-300;
	}
  }

  &__description{
	display: none;

	@include breakpoint(sm){
	  display: block;
	}
  }

  &__button{
	color: $accent-300;
	font-weight: bold;
	text-decoration: underline;
	transition: 0.5s all ease;

	&:hover{
	  color: $accent-400;
	}
  }
}