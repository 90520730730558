.reviews{
  padding: 4rem 0 7rem;

  @include breakpoint(sm){
	padding: 6rem 0 7rem;
  }

  @include breakpoint(lg){
	padding: 8.44rem 0 7rem;
  }

  @include breakpoint(xl){
	padding: 9rem 0;
  }

  &__inner{
	display: flex;
	flex-direction: column;
	gap: 1.87rem;

	@include breakpoint(lg){
	  display: grid;
	  grid-template-columns: 1fr auto;
	  grid-template-rows: 1fr 1fr;
	  grid-template-areas: 'reviews-slider reviews-title' 'reviews-slider reviews-buttons';
	  gap: 0 2rem;
	}
  }

  &__title{
	text-align: center;
	grid-area: reviews-title;

	@include breakpoint(lg){
	  align-self: flex-end;
	  margin-bottom: 3.75rem;
	}
  }

  &__slider{

	@include breakpoint(lg){
	  width: 40rem;
	}

	@include breakpoint(xl){
	  width: 53.12488rem;
	}

	&_wrap{
	  grid-area: reviews-slider;
	}
  }

  &__buttons{
	grid-area: reviews-buttons;
	display: flex;
	flex-direction: column;
	gap: 1.87rem;

	@include breakpoint(lg){
	  gap: 3.75rem;
	}
  }

  &_swiper__nav{
	margin-top: 0!important;
  }
}
