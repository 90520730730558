.factory-types{

  &__inner{
	display: flex;
	flex-direction: column;
	gap: 1.88rem;
	padding-top: 7rem;
	padding-bottom: 4rem;

	@include breakpoint(sm){
	  padding-top: 0;
	  padding-bottom: 0;
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  grid-template-rows:  auto 1fr 1fr;
	  grid-template-areas: 'factory-title factory-title' 'factory-item-1 factory-item-2' 'factory-item-3 factory-item-4';
	}

	@include breakpoint(lg){
	  padding-top: 5rem;
	  grid-template-rows: 1fr auto 1fr;
	  grid-template-areas: 'factory-item-1 factory-item-2' 'factory-title factory-title' 'factory-item-3 factory-item-4';
	}

	@include breakpoint(xxl){
	  gap: 1rem 8.12rem;
	}
  }

  &__title{
	grid-area: factory-title;
	z-index: 2;

	@include breakpoint(lg){
	  margin: -5.5rem 0!important;
	}
  }

  &__item{
	display: flex;
	flex-direction: column;
	gap: 1rem;
    max-width: 34.25rem;
    margin: 0 auto;

	@include breakpoint(lg){
	  max-width: 100%;
	}

	&--1{
	  grid-area: factory-item-1;

	  @include breakpoint(lg){
		flex-direction: column-reverse;
	  }
	}

	&--2{
	  grid-area: factory-item-2;

	  @include breakpoint(lg){
		flex-direction: column-reverse;
	  }
	}

	&--3{
	  grid-area: factory-item-3;
	}

	&--4{
	  grid-area: factory-item-4;
	}

	&-img{
	  display: block;
	  width: 100%;
	  border-radius: 2.5rem;
	  border: 2px solid $white;
	}

	&-caption{
	  text-align: center;
	  display: flex;
	  flex-direction: column;
	  gap: 0.38rem;
	}
  }

}


.about-section{

  &__images--factory{
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 0.85rem;
	max-width: 30rem;
	width: 100%;
	height: 18.8235rem;

	@include breakpoint(lg){
	  gap: 1.81rem;
	  height: 25rem;
	}

	@include breakpoint(xl){
	  max-width: 39.3125rem;
	  height: 39.25rem;
	  margin: 0;
	}

	img{
	  display: block;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  object-position: center;
	  border-radius: 2.5rem;
	  border: 2px solid $white;
	}
  }
}