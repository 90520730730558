.mask_path{
  display: block;
  max-width: 27.75rem;
  width: 100%;
  //flex-shrink: 0;
  mask-image: url("../../assets/images/mask.svg");
  mask-size: 100% 100%;
  /*mask-repeat: no-repeat;*/
  /*mask-position: center;*/
  transition: 0.5s all ease;
  position: relative;

  img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
	transition: 0.5s all ease;
  }

  &.video_link{
	&:before{
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(18, 133, 189, 0.77);
	  z-index: 1;
	}

	&:after{
	  content: '';
	  width: 2.5rem;
	  height: 2.5rem;
	  background: url("../../assets/images/fi-rr-play.svg")no-repeat center;
	  background-size: 0.83331rem 0.83331rem;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  z-index: 2;
	  border-radius: 50%;
	  border: 2px solid $white;

	  @include breakpoint(md){
		width: 3.75rem;
		height: 3.75rem;
		background-size: 1.25rem 1.25rem;
	  }
	}
  }
}

a.mask_path{
  &:hover{
	img{
	  transform: scale(1.1);
	}
  }
}