.button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  outline: none;
  border: 0;
  border-radius: 56.25rem;
  color: $base-400;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.625rem 5rem 0.625rem 1.5rem;
  background: linear-gradient(to right, $main-200, $main-400, $main-200, $main-300 );
  background-size: 300% 100%;
  height: 2.8125rem;
  line-height: normal;
  transition: all .4s ease-in-out;
  max-width: 22.375rem;
  width: 100%;
  cursor: pointer;

  @include breakpoint(sm){
	width: 16.25rem;
  }

  @include breakpoint(xl){
	height: 3.75rem;
	font-size: 1rem;
  }

  &:after{
	content: '';
	background: url("../../assets/images/fi-br-arrow-small-right.svg")no-repeat;
  	background-size: 100% 100%;
	width: 1.25rem;
	height: 1.25rem;
	position: absolute;
	right: 1.5rem;
	top: 50%;
	transform: translateY(-50%);
  }

  &:hover{
    background-position: 100% 0!important;
    transition: all .4s ease-in-out;
  }

  &--light{
	background: linear-gradient(to right, transparent, transparent, $main-200, $main-300 );
	background-size: 300% 100%;
	border: 2px solid $main-300;
  }
}