.social{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;

  a{
	height: 2.5rem;
	width: 2.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: linear-gradient(to right, $main-200, $main-400, $main-200, $main-300 );
	background-size: 300% 100%;
	transition: all .4s ease-in-out;

	&:hover{
	  background-position: 100% 0;
	  transition: all .4s ease-in-out;
	}

	img{
	  max-width: 100%;
	  display: block;
	}
  }
}