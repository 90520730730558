.bg-section{
  background: url("../../assets/images/bg4.png")no-repeat left;
  background-size: auto 100%;
  padding: 8rem 0 6rem;
  position: relative;
  z-index: 2;
  min-height: 330px;

  @include breakpoint(sm){
	background-size: 100% 100%;
  }

  @include breakpoint(md){
	padding: 10rem 0 6rem;
  }

  @include breakpoint(xl){
	padding: 13.11rem 0 11rem;
  }
}

.bg-section-white{
  margin: -4rem 0;
  padding: 5rem 0;
  position: relative;

  @include breakpoint(md){
	padding: 8.44rem 0 5rem;
  }

  @include breakpoint(xl){
	padding: 12rem 0 8.44rem;
  }

  &:after{
	content: '';
	position: absolute;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
	height: 9.8125rem;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: 2;

	@include breakpoint(xl){
	  height: 18rem;
	}
  }

  &__container{
	position: relative;
	z-index: 3;
  }
}

.section{

  &--second{
	background-position: top center!important;
	margin: 0;
	padding-top: 0;

	&:before{
	  content: '';
	  position: absolute;
	  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00), #fff, rgba(255, 255, 255, 0.00));
	  height: 9.8125rem;
	  width: 100%;
	  top: 0;
	  left: 0;
	  z-index: 2;

	  @include breakpoint(xl){
		height: 18rem;
	  }
	}
  }

  &__title{
	margin-bottom: 1.44rem;
	text-align: center;

	@include breakpoint(lg){
	  margin-bottom: 2.44rem;
	}
  }

  .brand-title{

	@include breakpoint(sm){
	  margin-top: 6rem;
	}

	@include breakpoint(lg){
	  margin-top: 10rem;
	}

  }

  &__button{
	margin: 1.88rem auto 0;

	@include breakpoint(lg){
	  margin: 3.6rem auto 0;
	}
  }

  &_swiper__nav{

	@include breakpoint(lg){
	  display: none!important;
	}
  }

  .swiper{

	@media (max-width: 575px) {
	  overflow: initial;
	  max-width: 350px;

	  &-slide{
		a{
		  pointer-events: none;
		}

		&-active{
		  a{
			pointer-events: initial;
		  }
		}
	  }
	}

  }

  &__buttons_group{
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	margin-top: 1.87rem;

	@include breakpoint(sm){
	  gap: 1.88rem;
	  flex-direction: row;
	  align-self: center;
	  justify-content: center;
	  margin-top: 3.75rem;
	}

	.button{
	  margin: 0 auto;
	  width: 100%;

	  @include breakpoint(sm){
		margin: 0;
		width: 16.25rem;
	  }
	}
  }
}

.heading-section{
  margin: 0;
  padding: 0;

  &__inner{
	position: relative;
	overflow: hidden;
	min-height: 20rem;
	padding-top: 10rem;

	&:before{
	  content: '';
	  background: url("../../assets/images/bg3top.png")no-repeat left center;
	  background-size: 100% 100%;
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: 1;
	  height: 151px;
	  width: 100%;
	}

	@include breakpoint(sm){
	  padding-top: 6.5rem;

	  &:before{
		display: none;
	  }
	}

	@include breakpoint(md){
	  padding-top: 10rem;

	  &:before{
		display: block;
		background: url("../../assets/images/bg3.png")no-repeat left;
		background-size: 100% 100%;
		height: 639px;
		width: 240px;
	  }

	  &:after{
		content: '';
		background: url("../../assets/images/bg2.png")no-repeat left;
		background-size: 100% 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		height: 601px;
		width: 146px;
	  }
	}

	@include breakpoint(xxl){
	  padding-top: 12rem;

	  &:before{
		height: 839px;
		width: 324px;
	  }

	  &:after{
		height: 801px;
		width: 246px;
	  }
	}
  }

  &__title{
	text-align: center;
	margin-bottom: 1.62rem;

	@include breakpoint(lg){
	  margin-bottom: 3.91rem;
	}
  }

  &__img{
	max-width: 7.125rem;
	display: block;
	margin: 0 auto;

	@include breakpoint(lg){
	  max-width: 19.125rem;
	}
  }
}