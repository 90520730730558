body{
  font-family: 'Proxima Nova Rg', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  background-color: $white;
  color: rgba($base-400, 0.8);
  font-size: 0.75rem;

  @include breakpoint(md){
    font-size: 1rem;
  }

  @include breakpoint(xxl){
    font-size: 1.125rem;
  }
}

a{
  color: $base-400;
  display: inline-block;
  text-decoration: none;
  line-height: 1.4;
  &:hover{
    color: $base-400;
  }
}

p{
  word-break: break-word;
  line-height: 1.4;

}

b, strong {
  font-weight: bold;
}

.container{
  max-width: 82.4375rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  main{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.accent_color-400{
  color: $accent-400;
}

.accent_color-300{
  color: $accent-300;
}

.text-center{
  text-align: center;
}

[data-background-image] {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.pb_0{
  padding-bottom: 0!important;
}

