.blog{

  &__new-articles{
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr;
	  align-items: center;
	}

	@include breakpoint(xl){
	  gap: 8.26rem;
	}

	&_first{

	  .blog-card{
		max-width: 27.75rem;
		margin: 0 auto;

		@include breakpoint(xl){
		  max-width: 42.3125rem;
		  margin: 0;
		}

		&__description{
		  display: block;
		}

		&__mask{
		  @include breakpoint(xl){
			max-width: 42.3125rem;
		  }
		}

		&__title{
		  @include breakpoint(xl){
			font-size: 4rem;
			line-height: 1.05;
		  }
		}
	  }

	}

	&_list{
	  display: grid;
	  grid-template-columns: 1fr;
	  gap: 2.5rem 0.77rem;

	  @include breakpoint(xs){
		grid-template-columns: 1fr 1fr;
	  }

	  @include breakpoint(sm){
		grid-template-columns: 1fr 1fr 1fr;
	  }

	  @include breakpoint(md){
		grid-template-columns: 1fr;
	  }

	  .blog-card{

		@include breakpoint(md){
		  display: grid;
		  grid-template-columns: 8rem 1fr;
		  gap: 1rem;
		}

		@include breakpoint(xl){
		  grid-template-columns: 14.52rem 1fr;
		  gap: 1.5rem;
		}

		&__description{
		  display: none;
		}
	  }


	}

	&_item{
	  &--3{
		display: none;

		@include breakpoint(sm){
		  display: block;
		}
	  }
	}
  }

  &__title{
	margin-bottom: 2rem;

	@include breakpoint(sm){
	  margin-bottom: 3rem;
	}
  }

  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem 0.77rem;
	margin-bottom: 1.82rem;

	@include breakpoint(xs){
	  grid-template-columns: 1fr 1fr;
	}

	@include breakpoint(sm){
	  gap: 2.5rem 2rem;
	}

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr 1fr;
	}

	@include breakpoint(xl){
	  grid-template-columns: 1fr 1fr 1fr 1fr;
	}
  }
}

.blog-detail__heading{
  padding: 1rem 0;
  margin-bottom: 1.62rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.31rem;

  @include breakpoint(sm){
	padding: 1.69rem 0;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 1.6875rem;
	text-align: left;
	margin-bottom: 3.31rem;
  }

  &_date{
	color: $accent-300;

	@include breakpoint(lg){
	  width: 19.2365rem;
	}
  }
}