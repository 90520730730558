.about-hisroty__images{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
  max-width: 30rem;

  @include breakpoint(md){
	gap: 1.88rem;
  }

  @include breakpoint(xl){
	max-width: 39.375rem;
  }

  &_item{

	img{
	  border-radius: 1.25rem;
	  border: 2px solid $white;
	  width: 100%;

	  @include breakpoint(md){
		gap: 2.5rem;
	  }
	}
  }

  &_description{
	background: url("../../assets/images/about_desc_bg.png")no-repeat;
	background-size: 100% 100%;
	width: 13.1875rem;
	text-align: center;
	color: $white;
	padding: 0.69rem 1.88rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@include breakpoint(md){
	  width: 19.47338rem;
	  height: 11.16563rem;
	  padding: 1.65rem 1.88rem;
	}
  }
}