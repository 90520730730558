.swiper-button-prev,
.swiper-button-next {
  color: $white;
  background-color: $accent-400;
  flex-shrink: 0;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  opacity: 1;
  border: 0;
  transition: 0.5s all ease;

  &:after{
    content: '';
    background: url("../../assets/images/fi-br-arrow-small-right-w.svg")no-repeat;
    background-size: 100% 100%;
	width: 1.5rem;
	height: 1.5rem;
  }

  &:hover{
    opacity: 0.8;
  }
}

.swiper-button-prev{
  &:after{
    transform: rotate(180deg);
  }
}

.swiper-pagination{
  &-bullet{
    background: rgba($base-400, 0.22);
    width: 0.5rem;
    height: 0.5rem;
	opacity: 1;

    &-active{
      background: $accent-400;
    }
  }
}

.swiper__nav{
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 1.88rem;

  @include breakpoint(lg){
	margin-top: 3.62rem;
  }

  &--center{
    justify-content: center;
  }

  &--left{
    justify-content: flex-start;
  }

  &--right{
    justify-content: flex-end;
  }

  .swiper-button-prev,
  .swiper-button-next{
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 0;
  }

  .swiper-pagination{
    position: relative;
    bottom: auto;
    left: auto;
    top: auto;
    right: auto;
    width: auto;
  }
}

.swiper-slide{
  display: flex;
  height: auto;
}



