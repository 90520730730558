@mixin breakpoint($point) {
  @if $point == xs {
    @media (min-width: 380px) { @content; }
  }
  @if $point == sm {
    @media (min-width: 576px) { @content; }
  }
  @else if $point == md {
    @media (min-width: 768px) { @content; }
  }
  @else if $point == lg {
    @media (min-width: 992px) { @content; }
  }
  @else if $point == xl {
    @media (min-width: 1320px) { @content; }
  }
  @else if $point == xxl {
    @media (min-width: 1900px) { @content; }
  }
}


