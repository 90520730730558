.post_content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h1, h2, h3, h4, h5, h6{
    color: $base-400;
  }

  strong{
    color: $accent-300;
  }


  a {
    color: $accent-300;
    transition: 0.5s ease;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: disc;
    margin-left: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;


    li {
      position: relative;
    }
  }

  ol {
    counter-reset: item;
	display: flex;
	flex-direction: column;
	gap: 1rem;

    & > li {
      position: relative;
      padding: 5px 0;

      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        display: inline-block;
        margin-right: 0.5em;
        text-align: right;
      }
    }
  }

  blockquote {
    font-size: 1.2rem;
    margin: 20px 0;
    line-height: 1.3;
    position: relative;
    padding: 10px 0 10px 20px;
    border-left: 5px solid $accent-300;

    p {
      padding: 0;
    }
  }

  .img {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  table {
    width: 100%
  }

  .grid{
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;

	@include breakpoint(lg){
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  gap: 3rem;
	}

	@include breakpoint(lg){
	  gap: 7.31rem;
	}
  }

  &.article_post{
	padding-bottom: 3rem;

	@include breakpoint(lg){
	  padding-bottom: 5rem;
	}

	@include breakpoint(xl){
	  padding-bottom: 8rem;
	}

	h3{
	  margin-bottom: 0;
		margin-top: 1rem;

	  @include breakpoint(lg){
		margin-bottom: 0;
		margin-top: 2.25rem;
	  }
	}
  }
}