.index-shop{
  background: url("../../assets/images/index-shop-bg-mob.png")no-repeat left;
  background-size: 100% 100%;

  @media (min-width: 400px){
	background: url("../../assets/images/bg4.png")no-repeat left;
	background-size: 100% 100%;
  }

  @include breakpoint(xxl){
	background: url("../../assets/images/index-shop-bg.png")no-repeat left;
	background-size: 100% 100%;
  }

  &__inner{
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;

	@include breakpoint(sm){
	  flex-direction: row;
	}
  }

  &__col{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	gap: 1.25rem;

	@include breakpoint(sm){
	  margin: 0!important;
	  padding: 0 0.75rem!important;
	}

	@include breakpoint(md){
	  padding: 0 0.75rem!important;
	}

	&:first-child{
	  padding-bottom: 2.5rem;
	  margin-bottom: 1.69rem;

	  &:before{
		content: '';
		background: url("../../assets/images/border_bg.svg")repeat-x;
		background-size: auto 100%;
		position: absolute;
		opacity: 0.2;
		height: 0.1875rem;
		width: 100%;
		bottom: 0;
		left: 0;

		@include breakpoint(sm){
		  background: url("../../assets/images/border_bg2.svg")repeat-y;
		  background-size: 100% auto;
		  width: 0.1875rem;
		  left: auto;
		  right: 0;
		  height: 100%;
		}

		@include breakpoint(xl){
		  width: 0.375rem;
		}
	  }
	}
  }

  &__img{
	display: block;
	max-width: 15rem;

	@include breakpoint(lg){
	  max-width: 100%;
	}

	@include breakpoint(xl){
	  margin-bottom: 3rem;
	}
  }

  &__button{
	width: 100%;

	@include breakpoint(lg){
	  width: 16.25rem;
	}
  }
}