.about-journey{

  &__title{
	text-align: center;
	margin-bottom: 1.8rem;

	@include breakpoint(lg){
	  display: none;
	}
  }

  &__images{
	max-width: 18.625rem;
	width: 100%;
	margin: 0 auto;
	position: relative;

	@include breakpoint(md){
	  max-width: 34.25rem;
	}

	@include breakpoint(xl){
	  max-width: 41.97338rem;
	}

	.img{
	  border-radius: 1.3125rem;
	  border: 2px solid $white;
	  width: 10.96713rem;
	  height: 16.5815rem;
	  object-fit: cover;
	  object-position: center;
	  display: block;
	  margin: 0 auto 3.5rem;
	  z-index: 1;
	  position: relative;

	  @include breakpoint(md){
		width: 20rem;
		height: 30rem;
		border-radius: 2.5rem;
	  }

	  @include breakpoint(xl){
		width: 28.8125rem;
		height: 43.5625rem;
	  }
	}

	.img_after{
	  width: 9.01219rem;
	  height: 6.06775rem;
	  position: absolute;
	  display: block;
	  left: 0;
	  bottom: 1.6rem;
	  z-index: 2;

	  @include breakpoint(md){
		width: 19.67363rem;
		height: 13.24588rem;
		bottom: -2rem;
	  }

	  @include breakpoint(md){
		left: -40px;
	  }
	}

	.img_before{
	  width: 6.82913rem;
	  height: 6.24138rem;
	  position: absolute;
	  display: block;
	  right: 0;
	  top: 7rem;

	  @include breakpoint(md){
		width: 14.908rem;
		height: 13.625rem;
		top: 15rem;
	  }

	  @include breakpoint(xl){
		top: 25rem;
	  }
	}

	.about-section__images_desc{
	  width: 10rem;
	  position: absolute;
	  right: 0;
	  bottom: 0;
	  z-index: 3;

	  @include breakpoint(md){
		width: 19.47338rem;
	  }

	  @include breakpoint(xl){
		padding: 1rem 1.7rem;
	  }
	}
  }

  &__description{

	.about-section__description-title{
	  display: none;

	  @include breakpoint(lg){
		display: block;
	  }
	}
  }
}