@font-face {
	font-family: 'Norican';
	src: url('../../assets/fonts/Norican/Norican-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('../../assets/fonts/ProximaNova/ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('../../assets/fonts/ProximaNova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
		url('../../assets/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('../../assets/fonts/ProximaNova/ProximaNova-Bold.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
		url('../../assets/fonts/ProximaNova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
		url('../../assets/fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('../../assets/fonts/ProximaNova/ProximaNova-RegularIt.eot');
	src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
		url('../../assets/fonts/ProximaNova/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/ProximaNova/ProximaNova-RegularIt.woff') format('woff'),
		url('../../assets/fonts/ProximaNova/ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../../assets/fonts/BebasNeue/BebasNeueRegular.eot');
    src: local('Bebas Neue Regular'), local('BebasNeueRegular'),
        url('../../assets/fonts/BebasNeue/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/BebasNeue/BebasNeueRegular.woff2') format('woff2'),
        url('../../assets/fonts/BebasNeue/BebasNeueRegular.woff') format('woff'),
        url('../../assets/fonts/BebasNeue/BebasNeueRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../../assets/fonts/BebasNeue/BebasNeueBold.eot');
    src: local('Bebas Neue Bold'), local('BebasNeueBold'),
        url('../../assets/fonts/BebasNeue/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/BebasNeue/BebasNeueBold.woff2') format('woff2'),
        url('../../assets/fonts/BebasNeue/BebasNeueBold.woff') format('woff'),
        url('../../assets/fonts/BebasNeue/BebasNeueBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  font-display: swap;
}