.cart {
  max-width: 43.125rem;
  width: 100%;
  margin: 0 auto;
  text-align: left;

  &__title {
    text-align: center;
    margin-bottom: 2rem;

    @include breakpoint(md) {
      margin-bottom: 3rem;
    }
  }

  &__table {
    width: 100%;

    &_item {
      border-bottom: 2px solid rgba($black, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.25rem;
      padding: 0.5rem 0;

      @include breakpoint(md) {
        padding: 1.31rem 0;
      }
    }

    &_img {
      border-radius: 0.75rem;
      border: 2px solid $white;
      overflow: hidden;
      width: 3.6875rem;
      flex-shrink: 0;
      height: 3.6875rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(md) {
        width: 5.8125rem;
        height: 5.8125rem;
      }

      img {
        display: block;
        width: 100%;
        max-height: 100%;
      }
    }

    &_price {
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      color: $base-400;
    }

    &_caption {
      width: 100%;
    }

    &_quantity {
      margin-top: 0.37rem;
      display: flex;
      align-items: center;
      gap: 5px;

      .quantity-btn {
        width: 20px;
        height: 20px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        border: 1px solid $base-400;
        background-color: $main-100;
        cursor: pointer;
        border-radius: 4px;
      }

      .quantity-btn:disabled {
        background-color: $base-200;
        cursor: not-allowed;
      }

      input[type="number"] {
        width: 35px;
        text-align: center;
        border: 1px solid $base-400;
        border-radius: 4px;
        line-height: 20px;
      }

      @include breakpoint(md) {
        margin-top: 0.75rem;
      }
    }

    &_title {
      font-size: 0.75rem;
      transition: 0.5s all ease;
      margin-bottom: 0.2rem;

      @include breakpoint(md) {
        font-size: 1.25rem;
      }

      &:hover {
        color: $base-400;
      }
    }

    &_remove {
      width: 0.75rem;
      height: 0.75rem;
      background: url("../../assets/images/close.svg") no-repeat;
      background-size: 100% 100%;
      transition: 0.5s all ease;
      opacity: 0.3;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__total {
    border-bottom: 2px solid rgba($black, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    padding: 1.25rem 0;

    @include breakpoint(md) {
      margin-bottom: 1.87rem;
      padding: 1.94rem 0;
    }
  }

  &__buttons-group {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.62rem;

    @include breakpoint(sm) {
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
    }
  }

  &__empty {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .empty_cart {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .button {
      margin-top: 1rem;
    }
  }

}

#loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;

  .spinner {
    width: 50px;
    height: 50px;
    animation: rotate 2s linear infinite;

    .path {
      stroke: $main-400;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -125;
  }
}

