.about-advantages{

  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.8rem;

	@include breakpoint(sm){
	  grid-template-columns: 1fr 1fr;
	}

	@include breakpoint(lg){
	  grid-template-columns: repeat(4, 1fr);
	}

	@include breakpoint(xl){
	  gap: 2.5rem;
	}
  }

  &__item{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.62rem;
	text-align: center;

	@include breakpoint(lg){
	  gap: 1.12rem;
	}

	&_icon{
	  width: 4.00581rem;
	  height: 3.13769rem;
	  background: url("../../assets/images/about_icon_bg.svg")no-repeat;
	  background-size: 100% 100%;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;

	  @include breakpoint(xl){
		margin-bottom: 0.76rem;
		width: 8.39438rem;
		height: 6.57519rem;
	  }

	  img{
		display: block;
		max-width: 100%;
	  }
	}

	&_title{

	  @include breakpoint(lg){
		span{
		  display: block;
		}
	  }
	}

	&_description{
	  padding-top: 0.62rem;

	  @include breakpoint(lg){
		padding-top: 1.12rem;
	  }
	}
  }
}