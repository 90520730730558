.contacts{
  &__list{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1.56rem;
	margin-bottom: 2.13rem;

	@include breakpoint(xl){
	  flex-wrap: nowrap;
	  justify-content: space-between;
	  margin-bottom: 2.34rem;
	}

	&_item{
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  text-align: center;
	  flex-direction: column;
	  gap: 0.75rem;
	  width: calc(50% - 1.56rem);

	  @include breakpoint(sm){
		width: calc(33.3% - 1.56rem);
	  }

	  @include breakpoint(xl){
		width: auto;
		flex-direction: row;
		text-align: left;
	  }

	  &-icon{
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid $accent-300;
		border-radius: 50%;
		transition: 0.5s all ease;

		@include breakpoint(lg){
		  width: 3.75rem;
		  height: 3.75rem;
		}

		&:hover{
		  opacity: 0.8;
		}

		img{
		  width: 1.125rem;
		  height: 1.125rem;
		  display: block;

		  @include breakpoint(lg){
			width: 1.5rem;
			height: 1.5rem;
		  }
		}
	  }

	  a{
		color: rgba($base-400 , 0.8);
		transition: 0.5s all ease;

		&:hover{
		  color: $base-400;
		}
	  }
	}
  }

  &__map{
	display: block;
	width: 100%;
	box-shadow: 0 14px 35px rgba(44, 46, 53, 0.17);
	border-radius: 1.25rem;
	margin-bottom: 1.81rem;

	@include breakpoint(lg){
	  margin-bottom: 2.13rem;
	}
  }

  &__button{
	margin: 0 auto;
  }
}