.reserve-table{

  &__inner{
	display: flex;
	flex-direction: column;
	gap: 1.77rem;
	align-items: center;
	padding: 0 0 3rem;

	@include breakpoint(sm){
	  padding: 3rem 0;
	}

	@include breakpoint(xl){
	  display: grid;
	  grid-template-columns: auto 1fr;
	  grid-template-rows: auto auto;
	  grid-template-areas: 'reserve-title reserve-image' 'reserve-button reserve-image';
	  gap: 3.12rem 2.95rem;
	}
  }

  &__title{
	text-align: center;

	@include breakpoint(xl){
	  grid-area: reserve-title;
	  max-width: 34rem;
	  text-align: left;
	  align-self: flex-end;
	}
  }

  &__button{

	@include breakpoint(xl){
	  grid-area: reserve-button;
	  align-self: flex-start;
	}
  }

  &__image{
	position: relative;
	max-width: 320px;
	margin: 0 auto;

	@include breakpoint(xl){
	  max-width: 43.08969rem;
	  grid-area: reserve-image;
	}

	.mask_path{
	  @include breakpoint(xl){
		max-width: 39.71469rem;
	  }
	}

	.img_top{
	  position: absolute;
	  display: block;
	  width: 10rem;
	  height: 7rem;
	  z-index: 2;
	  top: -2rem;
	  left: -2rem;

	  @include breakpoint(xl){
		width: 17.82819rem;
		height: 10.16275rem;
	  }
	}

	.img_bottom{
	  position: absolute;
	  display: block;
	  width: 8rem;
	  height: 14rem;
	  bottom: -2rem;
	  right: -2rem;
	  z-index: 2;

	  @include breakpoint(xl){
		width: 12rem;
		height: 25.22638rem;
		right: 0;
	  }
	}
  }
}