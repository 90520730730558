.menus{

  &__title{
	margin-bottom: 3.96rem;
	position: relative;
	border-radius: 1.25rem;
	padding: 1.06rem 1.25rem 3rem;
	border: 1px solid $accent-300;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 0.13rem;

	@include breakpoint(md){
	  flex-direction: row;
	  justify-content: space-between;
	  text-align: left;
	  align-items: center;
	  padding: 2.75rem;
	  margin-bottom: 6.31rem;
	}

	&_logo{
	  position: absolute;
	  width: 4.3125rem;
	  height: 4.3125rem;
	  display: block;
	  left: 0;
	  right: 0;
	  margin: auto;
	  bottom: -2.15rem;

	  @include breakpoint(md){
		width: 8.0625rem;
		height: 8.0625rem;
		bottom: -4.03rem;
	  }
	}

	&_description{

	  @include breakpoint(md){
		max-width: 15rem;
	  }

	  @include breakpoint(lg){
		max-width: 22.875rem;
	  }
	}
  }

  &__images{
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.87rem;

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr;
	}

	img{
	  display: block;
	  width: 100%;
	  box-shadow: 0 14px 35px 0 rgba(44, 46, 53, 0.04);
	}
  }
}

.menus__dishes{
  position: relative;
  overflow: hidden;

  @include breakpoint(xxl){
	&:before{
	  content: '';
	  background: url("../../assets/images/bird2.png")no-repeat;
	  background-size: 100% 100%;
	  width: 11.72194rem;
	  height: 25.03344rem;
	  position: absolute;
	  top: 16rem;
	  left: -1.5rem;
	}

	&:after{
	  content: '';
	  background: url("../../assets/images/bird1.png")no-repeat;
	  background-size: 100% 100%;
	  width: 13.41363rem;
	  height: 15.52825rem;
	  position: absolute;
	  bottom: 9rem;
	  right: -4rem;
	}
  }

  &--blue-bira{
	&:after{
	  width: 14.51531rem;
	  height: 23.73188rem;
	  background: url("../../assets/images/bird3.png")no-repeat;
	  background-size: 100% 100%;
	  right: -2rem;
	  bottom: 8rem;
	}
  }
}

