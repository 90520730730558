.breadcrumbs{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem 0;
  margin-bottom: 1.5rem;

  @include breakpoint(lg){
	margin-bottom: 2rem;
  }

  li{
	position: relative;

	a, p{
	  line-height: normal;
	}

	a{
	  transition: 0.5s all ease;
	  padding-right: 0.5rem;

	  &:after{
		content: '|';
		padding-left: 0.5rem;
	  }

	  &:hover{
		color: $accent-300;
	  }
	}

	p{
	  font-weight: bold;
	}
  }

  &__home{
	padding-left: 1.875rem;

	@include breakpoint(lg){
	  padding-left: 2.25rem;
	}

	&:before{
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 50%;
	  transform: translateY(-50%);
	  width: 1.125rem;
	  height: 1.125rem;
	  background: url("../../assets/images/fi-rr-home.svg")no-repeat;
	  background-size: 100% 100%;

	  @include breakpoint(lg){
		width: 1.5rem;
		height: 1.5rem;
	  }
	}
  }
}