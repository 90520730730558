.border-top-bg{
  position: relative;

  &:before{
	content: '';
	background: url("../../assets/images/border_bg.svg")repeat-x;
	background-size: auto 100%;
	position: absolute;
	opacity: 0.2;
	top: -0.09375rem;
	height: 0.1875rem;
	width: 100%;
	left: 0;

	@include breakpoint(xl){
	  top: -0.01875rem;
	  height: 0.375rem;
	}
  }
}

.border-bottom-bg{
  position: relative;

  &:after{
	content: '';
	background: url("../../assets/images/border_bg.svg")repeat-x;
	background-size: auto 100%;
	position: absolute;
	opacity: 0.2;
	height: 0.1875rem;
	bottom: 0;
	left: 0;
	width: 100%;

	@include breakpoint(xl){
	  height: 0.375rem;
	  bottom: -0.01875rem;
	}
  }
}
