.footer{
  background: url("../../assets/images/footer_bg.png")no-repeat left;
  background-size: auto 100%;
  padding: 0;

  @include breakpoint(sm){
	background-size: 100% 100%;
  }

  &__container{
	position: relative;
  }

  &__inner{
	display: flex;
	flex-direction: column;
	padding-top: 4rem;
	margin-bottom: 1.56rem;

	@include breakpoint(lg){
	  padding-top: 7rem;
	  display: grid;
	  grid-template-columns: auto 1fr;
	  grid-template-areas: 'footer-nav footer-nav' 'footer-logo footer-contacts';
	  gap: 0 4rem;
	  margin-bottom: 3.12rem;

	  @include breakpoint(xl){
		gap: 0 10.3125rem;
		padding-top: 9.5rem;
	  }
	}
  }

  &__area-logo{
	grid-area: footer-logo;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding-bottom: 1.56rem;
	position: relative;

	@include breakpoint(lg){
	  flex-direction: column;
	  justify-content: flex-start;
	  padding-bottom: 0;
	  gap: 1.88rem;
	}

	&:before {
	  content: '';
	  background: url("../../assets/images/border_bg.svg") repeat-x;
	  background-size: auto 100%;
	  position: absolute;
	  opacity: 0.2;
	  height: 0.1875rem;
	  width: 100%;
	  bottom: 0;
	  left: 0;

	  @include breakpoint(lg) {
		background: url("../../assets/images/border_bg2.svg") repeat-y;
		background-size: 100% auto;
		width: 0.1875rem;
		left: auto;
		right: -2rem;
		height: 100%;
	  }

	  @include breakpoint(xl) {
		width: 0.375rem;
		right: -5rem;
	  }

	}
  }

  &__area-nav{
	grid-area: footer-nav;
  }

  &__area-contacts{
	grid-area: footer-contacts;
  }

  &__go-to-top{
	color: $white;
	background-color: $accent-400;
	flex-shrink: 0;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	transition: 0.5s all ease;
	position: absolute;
	top: 0;
	right: 1rem;
	  display: none;

	@include breakpoint(xl){
	  width: 3.75rem;
	  height: 3.75rem;
	}

	&:after{
	  content: '';
	  background: url("../../assets/images/fi-br-arrow-small-right-w.svg")no-repeat;
	  background-size: 100% 100%;
	  width: 1.5rem;
	  height: 1.5rem;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: rotate(-90deg) translate(50%, -50%);
	}

	&:hover{
	  opacity: 0.8;
	}
  }

  &__logo{
	width: 3.875rem;
	height: 3.875rem;
	display: block;

	@include breakpoint(lg){
	  width: 8.0625rem;
	  height: 8.0625rem;
	}

	img{
	  display: block;
	  width: 100%;
	}

	&_group{
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  align-items: center;
	  gap: 0.56rem;

	  @include breakpoint(lg){
		gap: 1.88rem;
	  }
	}

	&_description{
	  color: rgba($base-400, .8);
	  text-align: center;
	  text-transform: uppercase;
	}
  }

  &__social{
	max-width: 9.1rem;

	@include breakpoint(sm){
	  max-width: 100%;
	}
  }

  &__nav{
	padding: 1.44rem 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.81rem;
	margin-bottom: 1.75rem;

	@include breakpoint(sm){
	  grid-template-columns: 1fr 1fr 1fr;
	}

	@include breakpoint(lg){
	  padding: 0 0 1.56rem;
	  margin-bottom: 2.95rem;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	}

	li{
	  a{
		transition: 0.5s all ease;
		&:hover{
		  color: $accent-300;
		}
	  }
	}
  }

  &__contacts{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1.56rem;
	margin-bottom: 1.56rem;

	@include breakpoint(xl){
	  flex-wrap: nowrap;
	  justify-content: space-between;
	}

	&_item{
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  text-align: center;
	  flex-direction: column;
	  gap: 0.75rem;
	  width: calc(50% - 1.56rem);

	  @include breakpoint(sm){
		width: calc(33.3% - 1.56rem);
	  }

	  @include breakpoint(xl){
		width: auto;
		flex-direction: row;
		text-align: left;
	  }

	  &-icon{
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid $accent-300;
		border-radius: 50%;
		transition: 0.5s all ease;

		&:hover{
		  opacity: 0.8;
		}

		img{
		  width: 1.125rem;
		  height: 1.125rem;
		  display: block;
		}
	  }

	  a{
		color: rgba($base-400 , 0.8);
		transition: 0.5s all ease;

		&:hover{
		  color: $base-400;
		}
	  }
	}
  }

  &__map{
	border-radius: 1.25rem;
	overflow: hidden;

	iframe{
	  border-radius: 1.25rem;
	  height: 11.25rem;

	  @include breakpoint(sm){
		height: 16rem;
	  }

	  @include breakpoint(lg){
		height: 19rem;
		border-radius: 2.5rem;
	  }
	}
  }

  &__bottom{
	background: rgba($white , 0.32);
	padding: 1.06rem 0;
	width: 100%;

	@include breakpoint(lg){
	  padding: 1.5rem 0;
	}

	&__inner{
	  display: flex;
	  justify-content: space-between;
	  flex-wrap: wrap;
	  align-items: center;
	  gap: 0.75rem;
	  color: rgba($base-400 , 0.8);

	  @include breakpoint(lg){
		gap: 2rem;
	  }
	}

	&_copyright{
	  width: 100%;
	  text-align: center;

	  @include breakpoint(sm){
		width: auto;
		text-align: left;
		margin-right: auto;
	  }
	}

	&_link{
	  color: rgba($base-400 , 0.8);
	  text-decoration: underline;
	  transition: 0.5s all ease;

	  &:hover{
		color: $base-400;
	  }
	}
  }
}