.product-price{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 45px;
  border-radius: 5.625rem;
  background-color: $base-400;
  color: $white;
  font-weight: bold;

  @include breakpoint(lg){
	width: 9.375rem;
	height: 3rem;
  }
}