body {
  .swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgba(44, 46, 53, 0.80);
    backdrop-filter: blur(40px);
  }

  .swal2-popup {
    width: 80.625rem;
    border-radius: 1.3125rem;
    font-family: 'Proxima Nova Rg', Arial, sans-serif;
    background: url("../../assets/images/popup_bg.png") no-repeat $white;
    background-size: cover;
    position: relative;
    overflow: hidden;

    @include breakpoint(md) {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -80px;
        display: block;
        background: url("../../assets/images/bg3.png") no-repeat left;
        background-size: auto 100%;
        height: 100%;
        width: 250px;
      }

      &:after {
        content: '';
        background: url("../../assets/images/index-shop-right.png") no-repeat left;
        background-size: auto 100%;
        position: absolute;
        top: 0;
        right: -100px;
        height: 100%;
        width: 246px;
      }
    }

    @include breakpoint(xl) {
      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    @include breakpoint(xxl) {
      border-radius: 2.5rem;
    }
  }

  .swal2-close {
    color: $base-400;
  }

  .swal_inner {
    max-width: 38.75rem;
    margin: 0 auto;
    color: $base-400;
    text-align: left;
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;

    @include breakpoint(md) {
      padding: 5rem 0;
    }

    .title {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .swal2-content {
    padding: 0;
    font-size: 0.75rem;

    @include breakpoint(md) {
      font-size: 1rem;
    }

    @include breakpoint(xxl) {
      font-size: 1.125rem;
    }
  }

  .errorlist {
    color: red;
    font-size: .8rem;
    text-align: center;
  }

  .valid {
    border-color: green;
  }

  button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  #phone-error {
    display: block;
    width: 100%;
  }

  .iti {
    width: 100% !important;

    &__search-input {
      padding: 10px;
    }
  }

  form.form {
    input::placeholder,
    textarea::placeholder {
      color: #888888;
      opacity: 1;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #888888;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: #888888;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {
      color: #888888;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: #888888;
    }

    select.form__input {
      color: #000;

      option[value=""],
      option:disabled {
        color: #888888;
      }

      &:not(:focus):has(option[value=""]:checked) {
        color: #888888 !important;
      }
    }
  }
}