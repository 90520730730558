h1, .h1{
  font-family: "Norican", sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.22;
  color: $base-400;

  @include breakpoint(sm){
	font-size: 3rem;
  }

  @include breakpoint(lg){
	font-size: 4rem;
  }

  @include breakpoint(xl){
	font-size: 5.5rem;
  }

  @include breakpoint(xxl){
	font-size: 6.25rem;
  }
}

h2, .h2{
  font-family: "Norican", sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.22;
  color: $base-400;


  @include breakpoint(sm){
	font-size: 3rem;
  }

  @include breakpoint(xl){
	font-size: 5rem;
  }
}

h3, .h3{
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.22;
  text-transform: uppercase;
  color: $base-400;

  @include breakpoint(lg){
	font-size: 2rem;
  }

  @include breakpoint(xxl){
	font-size: 2.5rem;
  }
}

h4, .h4{
  font-size: 1.5rem;
  line-height: 1.33;
  font-weight: 400;
  font-style: normal;
  color: $base-400;

  @include breakpoint(xxl){
    font-size: 2rem;
  }
}

h5, .h5{
  font-size: 1.125rem;
  line-height: 1.45;
  font-style: normal;
  color: $base-400;

  @include breakpoint(md){
    font-size:1.25rem;
  }

  @include breakpoint(xxl){
    font-size:1.5rem;
  }
}

h6, .h6{
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
  color: $base-400;

  @include breakpoint(md){
    font-size:1.1rem;
  }

  @include breakpoint(xxl){
    font-size:1.25rem;
    line-height: 1.3;
  }
}

.brand-title{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  color: $base-400;

  &:before{
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: -1.8rem;
	margin: auto;
	width: 2.07969rem;
	height: 1.84569rem;
	background: url("../../assets/images/logo_bird.svg")no-repeat;
	background-size: 100% 100%;

	@include breakpoint(sm){
	  width: 5.60088rem;
	  height: 4.97063rem;
	  top: -4.75rem;
	}
  }

  &--blue-bira{

	&:before{
	  top: -2.5rem;
	  width: 1.17406rem;
	  height: 2.76663rem;
	  background: url("../../assets/images/logo-bird2.svg")no-repeat;
	  background-size: 100% 100%;

	  @include breakpoint(sm){
		width: 4.02119rem;
		height: 9.47563rem;
		top: -8.4rem;
	  }
	}
  }

  &__text{
	background: url("../../assets/images/title_bg.png")no-repeat;
	background-size: 100% 100%;
	padding: 0 1.19rem;
	height: 3.125rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@include breakpoint(sm){
	  padding: 0 2.31rem;
	  height: 8.9375rem;
	}
  }
}

