.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  &__container {
    border-radius: 0 0 1.25rem 1.25rem;
    background: $white;
    box-shadow: 0 14px 35px 0 rgba(44, 46, 53, 0.04);
    transition: 0.3s height ease-in-out;

    @media (max-width: 1319.5px) {
      &.active {
        height: 35rem;
      }
    }

    @media (max-width: 767.5px) {
      &.active {
        height: 31rem;
      }
    }

  }

  &__inner {
    padding: 0 0.5rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include breakpoint(md) {
      height: 6.25rem;
      padding: 0 1.88rem;
      border-radius: 0 0 2.5rem 2.5rem;
    }
  }

  &__logo {
    align-self: flex-start;
    width: 3.875rem;
    height: 3.875rem;
    flex-shrink: 0;
    display: block;

    @include breakpoint(md) {
      width: 8.0625rem;
      height: 8.0625rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__button {
    display: none;

    @include breakpoint(md) {
      margin-left: auto;
      border: 2px solid $accent-300;
      border-radius: 56.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 3.75rem;
      width: 11.875rem;
      color: $accent-300;
      font-weight: bold;
      transition: 0.5s all ease;

      &:hover {
        color: $white;
        background-color: $accent-300;
      }
    }
    &.empty {
      @include breakpoint(md) {
        border-color: $white;
        color: $white;
        background-color: $white;
        cursor: auto;

        &:hover {
          color: $white;
          background-color: $white;
        }
      }
    }
  }

  &__cart {
    width: 2.5rem;
    height: 2.55rem;
    border: 2px solid $accent-300;
    background-color: $accent-300;
    margin-left: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    transition: 0.5s all ease;

    @include breakpoint(md) {
      width: 3.75rem;
      height: 3.75rem;
      background-color: $white;
      margin-left: 1.88rem;
    }

    &:hover {
      background-color: $accent-300;

      svg {
        path {
          fill: $white;
        }
      }
    }

    svg {
      width: 0.875rem;
      height: 0.875rem;
      flex-shrink: 0;

      @include breakpoint(md) {
        width: 1.37225rem;
        height: 1.5rem;

        path {
          fill: $accent-300;
        }
      }
    }
  }

  &__lang {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    height: 1rem;
    border-left: 1px solid $base-200;
    border-right: 1px solid $base-200;
    margin-left: 1rem;
    margin-right: auto;

    @include breakpoint(md) {
      margin-left: 1.94rem;
      height: 2.5rem;
      padding: 0 0.75rem;
    }

    li {
      padding: 0 0.5rem;

      &:first-child {
        border-right: 1px solid $base-200;
      }

      a, span {
        line-height: 1;
        color: $base-200;
      }

      span {
        color: $base-400;
        font-weight: bold;
      }
    }
  }

  nav {
    display: none;

    @media (max-width: 1319.5px) {

      &.active {
        display: block;
        position: absolute;
        top: 8rem;
        left: 0;
        width: 100%;
        padding-bottom: 1.88rem;
        height: 27rem;
        overflow-y: auto;
      }
    }

    @media (max-width: 767.5px) {
      &.active {
        top: 4.5rem;
      }
    }

    @include breakpoint(xl) {
      display: block;
    }
  }

  &__nav {

    @media (max-width: 1319.5px) {
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        background: url("../../assets/images/border_bg.svg") repeat-x;
        background-size: auto 100%;
        position: absolute;
        opacity: 0.2;
        top: 0;
        height: 0.1875rem;
        width: 100%;
        left: 0;
      }

      &-item {
        padding: 0.62rem 1.13rem;
        position: relative;
        font-family: "Bebas Neue", sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.22;
        text-transform: uppercase;
        color: $base-400;

        &:before {
          content: '';
          background: url("../../assets/images/border_bg.svg") repeat-x;
          background-size: auto 100%;
          position: absolute;
          opacity: 0.2;
          height: 0.1875rem;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }

      &-submenu {
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        width: 100%;
        background-color: $white;
        z-index: 2;

        &.open {
          left: 0;
        }

        &--back {
          padding: 0.63rem 0 0.63rem 2.5rem;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            width: 0.75rem;
            height: 0.75rem;
            background: url("../../assets/images/arrow_down.svg") no-repeat;
            background-size: 100% 100%;
          }
        }

        ul {
          padding: 1.25rem 1.13rem;
          display: flex;
          flex-direction: column;
          gap: 0.63rem;
          position: relative;

          &:after {
            content: '';
            background: url("../../assets/images/border_bg.svg") repeat-x;
            background-size: auto 100%;
            position: absolute;
            opacity: 0.2;
            height: 0.1875rem;
            top: 0;
            left: 0;
            width: 100%;
          }

          &:before {
            content: '';
            background: url("../../assets/images/border_bg.svg") repeat-x;
            background-size: auto 100%;
            position: absolute;
            opacity: 0.2;
            height: 0.1875rem;
            bottom: 0;
            left: 0;
            width: 100%;
          }
        }
      }

      .submenu_show {

        &.open {
          position: static;
        }

        .header__nav-link {
          padding-right: 2.5rem;

          &:after {
            content: '';
            position: absolute;
            right: 1.19rem;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            width: 0.75rem;
            height: 0.75rem;
            background: url("../../assets/images/arrow_down.svg") no-repeat;
            background-size: 100% 100%;
          }
        }

      }
    }


    @include breakpoint(xl) {
      display: flex;
      align-items: center;
      margin: 0 auto;
      gap: 2rem;

      li {
        a, p {
          color: $base-400;
          transition: 0.5s all ease;

          &:hover {
            color: $accent-300;
          }
        }
      }

      &-item {
        position: relative;

        a, p {
          transition: 0.5s all ease;
          position: relative;
          z-index: 2;
        }

        &:hover {
          .header__nav-link {
            font-weight: bold;
            color: $accent-300;
          }
        }
      }

      &-link {
        position: relative;
        cursor: pointer;
        display: block;

        &:after {
          display: block;
          content: attr(data-text);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;

          @media speech {
            display: none;
          }
        }
      }

      &-submenu {
        width: 13.188rem;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px);
        transition: 0.5s all ease-in-out;
        padding-top: 2rem;

        &--back {
          display: none;
        }

        ul {
          padding: 1.25rem;
          border-radius: 0 1.25rem 1.25rem 1.25rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          background-color: $white;
          box-shadow: 0 14px 35px 0 rgba(44, 46, 53, 0.04);
        }
      }

      .submenu_show {
        padding-right: 1.13rem;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 0.75rem;
          height: 0.75rem;
          background: url("../../assets/images/arrow_down.svg") no-repeat;
          background-size: 100% 100%;
        }

        &:hover {

          .header__nav-submenu {
            transition: 0.5s all ease-in-out;
            z-index: 1;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &_button {
      padding: 1.88rem 1.25rem 0;

      @include breakpoint(xl) {
        display: none;
      }
    }
  }


}

.navbar_toggler {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid $accent-300;
  margin-left: 0.5rem;
  background: url("../../assets/images/navbar.svg") no-repeat center;
  background-size: 1rem 1rem;

  @include breakpoint(md) {
    width: 3.75rem;
    height: 3.75rem;
    margin-left: 1rem;
    border-width: 2px;
  }

  @include breakpoint(xl) {
    display: none;
  }

  &.active {
    background: url("../../assets/images/navbar_active.svg") no-repeat center;
    background-size: 1rem 1rem;
  }
}