.gallery{

  &__slider{

	@include breakpoint(sm){
	  display: flex;
	  justify-content: center;
	  gap: 1.5rem;
	}

	@include breakpoint(lg){
	  gap: 3rem;
	}

	@include breakpoint(xl){
	  gap: 3.5rem;
	}
  }

  &__slider_main{
	position: relative;

	@include breakpoint(sm){
	  width: 310px;
	}

	@include breakpoint(md){
	  width: 450px;
	}

	@include breakpoint(lg){
	  width: 550px;
	}

	@include breakpoint(xl){
	  width: 720px;
	}

	&-pagination{
	  width: auto;
	  font-size: 1.5rem;
	  color: $accent-300;
	  font-family: "Norican", sans-serif;
	  font-weight: 400;
	  font-style: normal;
	  line-height: 1.22;
	  bottom: 0;

	  @include breakpoint(xl){
		font-size: 2.5rem;
	  }
	}

	&-title{
	  position: absolute;
	  right: 0;
	  bottom: 5px;

	  @include breakpoint(md){
		right: 3rem;
	  }

	  @include breakpoint(lg){
		right: 4rem;
		bottom: 28px;
	  }
	}

	.swiper {
	  padding-bottom: 3.5rem;

	  @include breakpoint(md){
		padding-right: 3rem;
	  }

	  @include breakpoint(lg){
		padding-right: 4rem;
		padding-bottom: 5rem;
	  }

	  @include breakpoint(xl){
		padding-right: 5rem;
	  }
	}

	.swiper__nav{

	  @include breakpoint(md){
		margin-top: 0;
		width: 2.625rem;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		flex-direction: column;
		display: flex!important;
	  }
	}

	.mask_path{
	  max-width: 39.5rem;
	}
  }

  &__slider_trumb{
	overflow: hidden;
	width: 0;
	height: 0;
	visibility: hidden;

	@include breakpoint(sm){
	  width: 220px;
	  height: auto;
	  visibility: visible;
	}

	@include breakpoint(md){
	  width: 260px;
	}

	@include breakpoint(lg){
	  width: 350px;
	}

	@include breakpoint(xl){
	  width: 500px;
	}
  }
}

.instagram-gallery{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;

  @include breakpoint(md){
	grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint(xl){
	gap: 1.88rem;
  }

  &__item{
	img{
	  border-radius: 2.5rem;
	  border: 2px solid $white;
	  display: block;
	  width: 100%;
	}
  }
}