.form{

  &__fildset{
	margin-bottom: 0.5rem;

	@include breakpoint(md){
	  margin-bottom: 0.75rem;
	}
  }

  &__grid{
	@include breakpoint(sm){
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  gap: 0 0.75rem;
	}
  }

  &__input{
	display: block;
	width: 100%;
	border-radius: 5.625rem;
	border: 1px solid $base-200;
	color: $base-400;
	background-color: $white;
	padding: 0 1.25rem;
	font-family: "Proxima Nova Rg", sans-serif;
	height: 2.8125rem;

	@include breakpoint(md){
	  height: 3.75rem;
	  padding: 0.625rem 1.875rem;
	}

	&::placeholder{
	  color: $base-400;
	  font-family: "Proxima Nova Rg", sans-serif;
	  font-style: normal;
	  font-weight: 400;
	  line-height: normal;
	}

	&:focus{
	  border-color: $accent-300;
	}
  }

  textarea{
	height: 5rem;
	resize: none;
	padding: 0.625rem 1.25rem;
	border-radius: 1.25rem;

	@include breakpoint(md){
	  padding: 1.12rem 1.875rem;
	  height: 6.25rem;
	}
  }

  &__select, select{
	background-image: url("../../assets/images/arrow_down.svg");
	background-repeat: no-repeat;
	background-size: 50px 12px;
	background-position: center right;
	-webkit-appearance: none;
  }

  &__label{
	margin-bottom: 0.81rem;
  }

  &__radio_group{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.85rem;

	@include breakpoint(md){
	  gap: 1rem;
	}
  }

  &__radio-input{
	display: block;
	position: relative;
	cursor: pointer;

	input{
	  position: absolute;
	  opacity: 0;
	  width: 0;
	  height: 0;
	  visibility: hidden;

	  &:checked + span{
		&:before{
		  background: url("../../assets/images/check.svg")no-repeat center $accent-300;
		  background-size: 20px 20px;
		}
	  }
	}

	span{
	  position: relative;
	  padding-left: 1.55rem;

	  &:before{
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 1.25rem;
		height: 1.25rem;
		border: 1px solid $base-200;
		border-radius: 50%;
		background-color: $white;
	  }
	}
  }

  &__button{
	margin: 0 auto;
  }

  .button{
	@include breakpoint(md) {
	  height: 3.75rem;
	}
  }
}