.product-detail {

  .add-to-card {
    cursor: pointer;
    padding: .625rem 3rem .625rem 1.5rem;
    @include breakpoint(lg) {
      padding: .625rem 5rem .625rem 1.5rem;
      height: 3rem;
    }
  }

  &__heading {
    margin-top: -7rem;
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    align-items: flex-start;

    @include breakpoint(lg) {
      margin-top: 0;
      margin-bottom: 5.69rem;
      padding-bottom: 6.25rem;
      gap: 3.75rem;
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }

  &__gallery {
    position: relative;
    overflow: hidden;
    border-radius: 2.5rem;
    border: 2px solid $white;
    background: $white;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    padding: 1.25rem 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 1.25rem;
      width: 100%;
      background: linear-gradient(180deg, rgba(44, 46, 53, 0.09) 0%, rgba(44, 46, 53, 0.00) 100%,);
    }

    @include breakpoint(xl) {
      padding: 3.2rem 2.87rem;
      max-width: 39.25rem;

      &:after {
        height: 3.2rem;
      }
    }

  }

  &__caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1.88rem;

    .price-container {
      display: flex;
      align-items: center;
      gap: 10px;

      a {
        height: 45px;
      }
    }

    @include breakpoint(lg) {
      align-items: flex-start;
      text-align: left;
    }

    @include breakpoint(xl) {
      gap: 2.12rem;
    }
  }

  &__description {
    border-radius: 1.3125rem;
    background: $white;
    box-shadow: 0 14px 35px 0 rgba(44, 46, 53, 0.17);
    padding: 1.63rem 1.25rem 1.87rem;

    @include breakpoint(md) {
      padding: 4.44rem 4.37rem 5rem;
    }
  }
}

.product-gallery__slider {

  .swiper-slide {
    a {
      display: block;
      margin: 0 auto;
    }

    img {
      display: block;
      max-width: 410px;
      width: 100%;
    }
  }
}