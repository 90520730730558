.product-card{
  width: 100%;
  text-align: center;

  &__img_wrap{
	position: relative;
	overflow: hidden;
	border-radius: 2.5rem;
	border: 2px solid $white;
	background: $white;
	transition: 0.5s all ease;
	margin-bottom: 1.25rem;

	@include breakpoint(lg){
	  margin-bottom: 1.88rem;
	}

	&:after{
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 3.75rem;
	  width: 100%;
	  background: linear-gradient(180deg, rgba(44, 46, 53, 0.09) 0%, rgba(44, 46, 53, 0.00) 100%,);
	  z-index: 1;
	}

	&:before{
	  content: '';
	  background: linear-gradient(0deg, rgba(44, 46, 53, 0.92) 0%, rgba(44, 46, 53, 0.92) 100%);
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	  z-index: 1;
	  opacity: 0;
	  transition: 0.5s all ease;
	}

	&:hover{

	  &:before{
		opacity: 1;
	  }

	  .product-card__img{
		transform: scale(1.1);
	  }

	  .product-card__button{
		opacity: 1;
		visibility: visible;
	  }

	  .product-card__price{
		background-color: $white;
		color: $base-400;
	  }
	}
  }

  &__img{
	display: block;
	width: 100%;
	transition: 0.5s all ease;
  }

  &__price{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 1.4rem;
	margin: auto;
	z-index: 2;

	@include breakpoint(lg){
	  bottom: 1.88rem;
	}
  }

  &__button{
	width: 12.5rem;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: 0.5s all ease;
	z-index: 2;
	opacity: 0;
	visibility: hidden;

	@include breakpoint(lg){
	  width: 16.25rem;
	}
  }

  &__title{
	margin-bottom: 0.62rem;
	transition: 0.5s all ease;

	&:hover{
	  color: $accent-300;
	}
  }

	&__attr{
		span{
			padding: 0 10px;
		}
	}

}