.about-section{

  &--order-lg-0{
  	@include breakpoint(lg){
	  order: 0;
	}
  }

  &--order-lg-1{
  	@include breakpoint(lg){
	  order: 1;
	}
  }

  &__title{
	text-align: center;
	margin-bottom: 1.44rem;

	@include breakpoint(lg){
	  text-align: left;
	  margin-bottom: 2.44rem;
	}
  }

  &__inner{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;

	@include breakpoint(lg){
	  flex-direction: row;
	  gap: 1.5rem;
	  justify-content: space-between;
	  align-items: center;
	}
  }

  &__description{
	max-width: 34.25rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;

	@include breakpoint(lg){
	  margin: 0;
	}

	&--order-lg-0{
	  @include breakpoint(lg){
		order: 0!important;
	  }
	}

	&-item{
	  padding: 0.75rem 0;
	  text-align: center;
	  color: rgba($base-400, 0.8);

	  @include breakpoint(lg){
		text-align: left;
		padding: 1.5rem 0;
	  }
	}

	&-title{
	  text-align: center;
	  margin-bottom: 1.5rem;

	  @include breakpoint(lg){
		text-align: left;
		margin-bottom: 2.5rem;
	  }
	}
  }

  &__post_content{
	padding-top: 1.06rem;
	text-align: center;

	@include breakpoint(lg){
	  text-align: left;
	  padding-top: 2.5rem;
	}
  }

  &__button{
	margin: 1.88rem auto 0;
	width: 100%;

	@include breakpoint(sm){
	  width: 16.25rem;
	}

	@include breakpoint(lg){
	  margin: 1.88rem 0 0;
	}
  }

  &__video_link{
	margin: 0 auto;

	@include breakpoint(xl){
	  max-width: 32rem;
	}
  }

  &__images{
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 auto;

	.img{
	  border-radius: 1.3125rem;
	  display: block;
	  max-width: 100%;
	  border: 4px solid $white;
	  position: relative;
	  z-index: 1;
	}

	&_desc{
	  background: url("../../assets/images/img3.png")no-repeat;
	  background-size: 100% 100%;
	  padding: 0.44rem 0.875rem;
	  text-align: center;
	  color: $accent-400;
	  position: relative;
	  z-index: 4;
	}

  }

}