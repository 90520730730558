.main-hero{
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include breakpoint(lg){
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 1100px;
	margin: 0 auto;
	padding: 5rem 0;
  }

  @include breakpoint(xxl){
	max-width: 100%;
  }

  &__video{
	&_link{
	  margin: 0 auto;

	  @include breakpoint(xl){
		max-width: 32rem;
	  }

	  @include breakpoint(xxl){
		max-width: 38.5625rem;
	  }
	}
  }

  &__caption{
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@include breakpoint(sm){
	  gap: 1.5rem;
	}

	@include breakpoint(lg){
	  text-align: left;
	  align-items: flex-start;
	}

	@include breakpoint(xl){
	  gap: 2.88rem;
	}
  }

  &__buttons{
	margin-top: 0;
  }

  &__description{
	color: rgba($base-400, 0.6);
  }
}