$accent-400: #1275AE;
$accent-300: #108DC5;

$main-400: #F6B138;
$main-300: #FBC438;
$main-200: #FED338;
$main-100: #ffe177;

$base-400: #2C2E35;
$base-200: #DDE0E0;

$black: #000;
$white: #fff;

.m-auto {
    margin: auto !important;
}
.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
}
.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
}
