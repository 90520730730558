.manufacture{

  &__inner{
	display: flex;
	flex-direction: column;
	position: relative;

	@include breakpoint(lg){
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  grid-template-areas: 'manufacture-item-1 manufacture-item-2'
							'manufacture-title manufacture-title'
							'manufacture-item-3 manufacture-item-3'
							'manufacture-button manufacture-button';
	  gap: 0 12.03rem;
	}
  }

  &__title{
	margin-bottom: 1.88rem;
	grid-area: manufacture-title;

	@include breakpoint(lg){
	  margin-top: 3.06rem;
	  margin-bottom: 3rem;
	}
  }

  &__item{
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 1rem;
    max-width: 34.25rem;
    margin: 0 auto;

	@include breakpoint(lg){
	  padding: 1.5rem 0;
	}

	&--1{
	  grid-area: manufacture-item-1;
	}

	&--2{
	  grid-area: manufacture-item-2;

	  &:before, &:after{
		opacity: 0;

		@include breakpoint(lg){
		  opacity: 0.2;
		}
	  }
	}

	&--3{
	  grid-area: manufacture-item-3;
	}
  }

  &__button{
	margin: 1.88rem auto 0;
	grid-area: manufacture-button;

	@include breakpoint(lg){
	  margin-top: 2.63rem;
	}
  }

  &__left_img{
	display: none;

	@include breakpoint(xl){
	  position: absolute;
	  left: 0;
	  bottom: 0;
	  display: block;
	}
  }

  &__right_img{
	display: none;

	@include breakpoint(xl){
	  position: absolute;
	  right: 0;
	  bottom: 0;
	  display: block;
	}
  }
}