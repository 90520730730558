body {
  .swal2-popup {
    &.popup-add-to-card {
      &:before {
        left: 0;
      }

      &:after {
        right: -130px;
      }
    }

    .swal2-confirm {
      background-image: linear-gradient(to right, #FED338, #F6B138, #FED338, #FBC438) !important;
    }

  }
}