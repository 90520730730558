.about-section{

  &__images--coffee-shops {

	@include breakpoint(lg){
	  width: 30rem;
	}

	@include breakpoint(xl){
	  width: 39rem;
	}

	.img_logo{
	  width: 3.25rem;
	  height: 3.25rem;
	  display: block;
	  position: absolute;
	  top: -1rem;
	  right: 0;
	  z-index: 2;

	  @include breakpoint(md){
		width: 7rem;
		height: 7rem;
	  }

	  @include breakpoint(xl){
		width: 8.75rem;
		height: 8.75rem;
		top: -4rem;
		right: 3.5rem;
	  }
	}

	.about-section__images_desc{
	  width: 11.5rem;
	  margin: -5rem auto 0;

	  @include breakpoint(md){
		width: 14.5rem;
	  }

	  @include breakpoint(xl){
		width: 19.47338rem;
		padding: 2.5rem 1.5rem;
		margin-top: -6rem;
	  }
	}

	.mask_path{
	  margin: 0 auto;

	  @include breakpoint(lg){
		max-width: 39.71469rem;
	  }
	}
  }
}