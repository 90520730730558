.shop{

  &__sort{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;
	margin-bottom: 1.87rem;

	@include breakpoint(sm){
	  margin-bottom: 3.75rem;
	  gap: 1.5rem;
	}

	li{
	  width: 100%;
	  max-width: 12.5rem;
	}

	&_item{
	  width: 100%;
	  height: 2.8125rem;
	  display: flex;
	  align-items: center;
	  text-align: center;
	  justify-content: center;
	  border-radius: 5.625rem;
	  border: 1px solid $base-200;
	  padding: 0 0.5rem;
	  line-height: normal;
	  transition: 0.5s all ease;

	  &:hover, &.active{
		background-color: $accent-300;
		color: $white;
		border-color: $accent-300;
	  }
	}
  }

  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.88rem;

	@media (min-width: 480px){
	  grid-template-columns: 1fr 1fr;
	  gap: 1.88rem 1rem;
	}

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr 1fr;
	  gap: 3.75rem 1.88rem;
	}
  }
}