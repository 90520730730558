.order{

  &__form{
	border-radius: 1.25rem;
	border: 1px solid $accent-300;

	@include breakpoint(lg){
	  display: flex;
	  border-width: 2px;
	}

	&_col{
	  width: 100%;
	  border-bottom: 2px solid rgba($accent-300, 0.1);

	  @include breakpoint(lg){
		border-right: 2px solid rgba($accent-300, 0.1);
		border-bottom: 0;
	  }

	  &:last-child{
		border: 0;
	  }
	}

	&_wrap{
	  padding: 1rem 1.25rem;
	  border-bottom: 2px solid rgba($accent-300, 0.1);

	  &:last-child{
		border: 0;
	  }

	  @include breakpoint(sm){
		padding: 2rem 1.5rem;
	  }

	  @include breakpoint(xl){
		padding: 3.56rem 4.06rem 4.06rem;
	  }
	}
  }

  .cart__table_item{
	border-color: rgba($accent-300, 0.1);
  }

  .cart__total{
	border-color: rgba($accent-300, 0.1);
  }
}

